<template>
  <section style="height: 100%;">
    <div class="flex align-center" style="padding: 8px 0;margin-top: 30px;">
      <img :src="bs" alt=""/>
      <span style="color:#FFFFFF;font-size: 18px;font-weight: bold;margin-left: 10px">区域占比</span>
    </div>
    <div style="height: 78%;border: 1px #2BA3FF dashed;padding: 10px">
      <div class="flex justify-between" style="height: 100%;">
        <div style="height: 92%;width: 49.8%;">
          <div id="regionECharts" style="height: 66%;"></div>
          <div style="height: 33%;">
            <div class="flex justify-center"
                 style="text-align: center;height: 100%;padding: 5px 10px;font-weight: 400;color: #FFFFFF">
              <div class="flex align-center"
                   style="height: 100%;width: 49%;background: #2E4CCF;box-shadow: inset 0 0 22px 1px #03024A;">
                <div style="width: 100%;">
                  <div>{{ pie[1] ? pie[1].value : '' }}</div>
                  <div style="font-size: 12px;">{{ pie[1] ? pie[1].name : '' }}</div>
                  <div>{{ pie[1] ? (pie[1].rate + '%') : '' }}</div>
                </div>
              </div>
              <div class="flex align-center"
                   style="height: 100%;width: 49%;background: linear-gradient(180deg, #6DD400 0%, #44D7B6 100%);">
                <div style="width: 100%;">
                  <div>{{ pie[0] ? pie[0].value : '' }}</div>
                  <div style="font-size: 12px;">{{ pie[0] ? pie[0].name : '' }}</div>
                  <div>{{ pie[0] ? (pie[0].rate + '%') : '' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-carousel style="height: 100%;width: 49.8%;" :autoplay="false" indicator-position="outside"
                     arrow="never">
          <el-carousel-item v-for="(item,index) in list" :key="index">
            <div style="height: 33%;" v-for="(item1,index1) in item" :key="index1">
              <div style="height: 100%;padding: 5px 10px;">
                <div :class="index1===0?'qy0':'qy1'" style="height: 100%;font-size: 12px;color:#FFF;text-align: center;">
                  <div class="flex justify-center" style="padding-top: 10px;height: 70%">
                    <div style="width: 49%;">
                      <div style="width: 100%;">
                        <div style="font-size: 16px;">{{ item1.number }}</div>
                        <div>总房数</div>
                      </div>
                    </div>
                    <div style="border-left: 1px #F2F3FF dashed;width: 49%;">
                      <div style="width: 100%;">
                        <div style="font-size: 16px;">{{ item1.value }}</div>
                        <div>已出租</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span>{{item1.name}}</span>
                    <span style="color:#5EA4FF;margin-left: 10px">{{item1.rate}}%</span>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </section>
</template>

<script>
import * as echarts from "echarts";

const bs = require("@images/icon/bs.png");
import {getDistrictRentCondition} from "@/api/data-screen";

export default {
  name: "region-proportion",
  data() {
    return {
      bs,
      ECharts: null,
      options: {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '80%'],
            label: {
              normal: {
                show: false,
              }
            },
            data: [],
            tooltip: {
              valueFormatter(value) {
                return value + ' %';
              }
            },
          }
        ]
      },
      pie: [],
      list: [],
    }
  },
  components: {},
  methods: {
    eChartsInit(eChartsName, eChartsOptions) {
      let {...options} = eChartsOptions;
      this[eChartsName] = echarts.init(document.getElementById(eChartsName));
      this[eChartsName].setOption(options, true);
      window.addEventListener("resize", () => {
        this[eChartsName].resize();
      });
    },
    formatOption(eChartsOptions, data) {
      const {series} = eChartsOptions;
      data = data.map(v => {
        const {name, rate: value} = v;
        return {name, value}
      });
      data[0].itemStyle = {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: '#6DD400'},
          {offset: 1, color: '#44D7B6'}
        ])
      }
      data[1].itemStyle = {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: '#0090FF'},
          {offset: 1, color: '#507AEE'}
        ])
      }
      series[0].data = data;
      eChartsOptions.series = series;
      return eChartsOptions;
    },
    getDistrictRentCondition() {
      const that = this;
      const {options} = that;
      getDistrictRentCondition().then(res => {
        const {info: {list = [], pie}} = res;
        const eChartsOptions = that.formatOption(options, pie);
        that.eChartsInit("regionECharts", eChartsOptions);
        that.pie = pie;
        let array = [];
        let arrayList = [];
        list.map((v, i) => {
          array.push({...v});
          if (array.length === 3) {
            arrayList.push([...array]);
            array = [];
          }
          if (i === list.length - 1) {
            arrayList.push([...array]);
            array = [];
          }
        });
        that.list = arrayList;
      });
    }
  },
  mounted() {
    this.getDistrictRentCondition();
    /*const {options} = this;
    const data = [
        {value: 70, name: 'Search',},
        {value: 30, name: 'Direct',},
    ]
    const eChartsOptions = this.formatOption(options, data);
    this.eChartsInit("regionECharts", eChartsOptions);*/
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-carousel__container {
  position: relative;
  height: 92%;
}

/deep/ .el-carousel__indicators--outside {
  text-align: right;
  position: static;
  transform: none;
}

/deep/ .el-carousel__indicator--horizontal {
  display: inline-block;
  padding: 0 4px;
}

.qy0 {
  background: linear-gradient(90deg, #EF9A50 0%, #F31900 100%);
}

.qy1 {
  background: #2E4CCF;
  box-shadow: inset 0 0 22px 1px #03024A;
}
</style>